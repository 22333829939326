/**
* 基础菜单 商品管理
*/
<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>系统配置</span>
            </div>
            <div>
                <el-descriptions class="margin-top" title="" :column="1" border>

                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-bank-card"></i>
                            首页副标题
                        </template>
                        <div class="desc-item">
                            <div>{{ setting.subtitle }}</div>
                            <i class="el-icon-edit font-size-20" @click="edit('subtitle', '副标题', 1)"></i>
                        </div>

                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-document-copy"></i>
                            服务协议
                        </template>
                        <div class="desc-item">
                            <div>{{ setting.agreement }}</div>
                            <i class="el-icon-edit font-size-20" @click="edit('agreement', '服务协议', 2)"></i>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-lock"></i>
                            隐私政策
                        </template>
                        <div class="desc-item">
                            <div>{{ setting.privacyPolicy }}</div>
                            <i class="el-icon-edit font-size-20" @click="edit('privacyPolicy', '隐私政策', 2)"></i>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-picture"></i>
                            AI头像设置
                        </template>
                        <div class="desc-item">
                            <div>
                                <el-image v-show="setting.aiImage" :src="setting.aiImage"
                                    style="width: 100px;height: 100px; "></el-image>
                            </div>
                            <el-upload :action="uploadImagePath" :show-file-list="false" :headers="headers"
                                :on-success="handleAiSuccess">
                                <i class="el-icon-picture-outline font-size-20"></i>
                            </el-upload>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-picture"></i>
                            首页广告图
                        </template>
                        <div class="desc-item">
                            <div>
                                <el-image v-show="setting.homeImage" :src="setting.homeImage"
                                    style="width: 200px;height: 200px; "></el-image>
                            </div>
                            <el-upload :action="uploadImagePath" :show-file-list="false" :headers="headers"
                                :on-success="handleAvatarSuccess">
                                <i class="el-icon-picture-outline font-size-20"></i>
                            </el-upload>

                        </div>
                    </el-descriptions-item>

                </el-descriptions>
            </div>
        </el-card>
        <el-dialog :title="title + '修改'" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="350px">
            <div style="width: 100%;display: flex;justify-content: center;">
                <el-form style="width: 250px;">
                    <el-form-item label="">
                        <el-input v-if="inputType == 1" v-model="inputValue" autocomplete="off"></el-input>
                        <el-input v-else type="textarea" v-model="inputValue" :rows="10" placeholder="请输入内容">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleDialog">取 消</el-button>
                <el-button size="small" type="primary" @click="confirmDialog">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getSettingReq, updateSettingReq } from '../../api/userMG'
import { getUploadImagePath } from '../../utils/util'
export default {
    data() {
        return {
            setting: {
                subtitle: '',
                homeImage: '',
                aiImage: '',
                agreement: '',
                privacyPolicy: '',
                tenantId: '',
                id: ''
            },
            dialogFormVisible: false,
            form: '',
            title: '',
            inputType: '',
            inputValue: '',
            property: '',
            uploadImagePath: '',
        }
    },

    /**
     * 数据发生改变
     */

    /**
     * 创建完毕
     */
    created() {
    },
    mounted() {
        this.uploadImagePath = getUploadImagePath()
        this.getData()
    },

    computed: {
        headers() {
            return {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('accessToken')
            }
        }
    },
    /**
     * 里面的方法只有被调用才会执行
     */
    methods: {
        getData() {
            this.getSetting()
        },
        async getSetting() {
            let res = await getSettingReq()
            if (res.code === 200) {
                this.setting = res.data
            } else {
                this.$message.error(res.message)
            }
            console.log("getSetting", res)
        },
        edit(property, title, type) {
            this.inputValue = this.setting[property]
            this.property = property
            this.title = title
            this.inputType = type
            this.dialogFormVisible = true
        },
        confirmDialog() {
            if (this.inputValue) {
                this.setting[this.property] = this.inputValue
                this.dialogFormVisible = false
                this.updataSetting()
            } else {
                this.$message.error("不能为空！")
            }

            //this.resetEdit()
        },
        cancleDialog() {
            this.dialogFormVisible = false
            // this.resetEdit()
        },
        resetEdit() {
            this.inputValue = ''
            this.title = ''
            this.inputType = ''
            this.property = ''
        },
        handleAiSuccess(response) {
            console.log("上传图片", response)
            if (response.code === 200) {
                this.setting.aiImage = response.data
                this.updataSetting()

            }
        },
        handleAvatarSuccess(response) {
            console.log("上传图片", response)
            if (response.code === 200) {
                this.setting.homeImage = response.data
                this.updataSetting()

            }
        },
        async updataSetting() {
            let res = await updateSettingReq(this.setting)
            if (res.code === 200) {
                this.$message.success("修改成功！")
                this.getSetting()
            } else {
                this.$message.error(res.message)
                this.getSetting()
            }
        }


    }
}
</script>

<style scoped>
.user-search {
    margin-top: 20px;
}

.userRole {
    width: 100%;
}

.box-card {
    width: 480px;
    margin-top: 50px;
    margin-left: 30px;

}

.desc-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
}

.font-size-20 {
    font-size: 16px;
}
</style>