// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index';
/**
 * 基础菜单
 */
// 商品管理

//租户管理
import TenantList from '@/views/tenant/TenantList';
//用户管理
import UserList from '@/views/user/UserList';
//场景管理
import SceneList from '@/views/scene/SceneList';
import TenantSceneList from '@/views/scene/TenantSceneList.vue';
//系统设置
import Setting from '@/views/system/Setting';
// 场景分类
import SceneClassList from '@/views/scene/SceneClassList'
//场景训练记录
import PracticeRecord from '@/views/record/PracticeRecord.vue';
//用户训练记录总统计
import GeneralReport from '@/views/report/GeneralReport.vue';
//用户月统计
import MonthReport from '@/views/report/MonthReport.vue';
//用户日统计
import DayReport from '@/views/report/DayReport.vue';
//月训练频率
import MonthFrequencyReport from '@/views/report/MonthFrequencyReport.vue';
//日训练频率
import DayFrequencyReport from '@/views/report/DayFrequencyReport.vue';
//修改密码
import UserConfig from '@/views/system/UserConfig.vue';
//问题反馈
import Suggestion from '@/views/suggestion/Suggestion.vue';




/**
 * 订单管理
 */
// 交易订单
/**
 * 系统管理
 */
// 用户管理
import user from '@/views/system/user';
// 菜单管理
import Module from '@/views/system/Module';
// 角色管理
import Role from '@/views/system/Role';
// 公司管理
import Dept from '@/views/system/Dept';
// 系统环境变量
import Variable from '@/views/system/Variable';
// 权限管理
import Permission from '@/views/system/Permission';
/**
 * 支付管理
 */
// 支付配置信息
// 支付配置
/**
 * 数据监控
 */
// 监控查询

// 图表界面

// 启用路由
Vue.use(Router);

// 导出路由 
export default new Router({
    routes: [{
        path: '/',
        name: '',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/login',
        name: '登录',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/index',
        name: '首页',
        component: index,
        iconCls: 'el-icon-tickets',
        children: [{
            path: '/tenant/TenantList',
            name: '租户列表',
            component: TenantList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/user/UserList',
            name: '用户管理',
            component: UserList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/scene/SceneList',
            name: '场景管理',
            component: SceneList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/scene/SceneClassList',
            name: '场景分类管理',
            component: SceneClassList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/scene/TenantSceneList',
            name: '场景管理',
            component: TenantSceneList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/record/PracticeRecord',
            name: '场景训练记录',
            component: PracticeRecord,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/report/GeneralReport',
            name: '角色管理',
            component: GeneralReport,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/report/MonthReport',
            name: '用户月训练报表',
            component: MonthReport,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/report/DayReport',
            name: '用户月训练报表',
            component: DayReport,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/system/UserConfig',
            name: '修改密码',
            component: UserConfig,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/report/DayFrequencyReport',
            name: '日训练频率报表',
            component: DayFrequencyReport,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/report/MonthFrequencyReport',
            name: '月训练频率报表',
            component: MonthFrequencyReport,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/system/Setting',
            name: '权限管理',
            component: Setting,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/suggestion/Suggestion',
            name: '问题反馈',
            component: Suggestion,
            meta: {
                requireAuth: true
            }
        }]
    }]
})