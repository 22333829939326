import axios from 'axios';
import { loginreq, req } from './axiosFun';

// 登录接口 
export const login = (params) => { return req("post", "/tenant/login", params) };

export const logout = (params) => { return req("post", "/token/logout", params) }

export const systemUserLogin = (params) => { return req("post", "/system/login", params) };

// 登录获取手机号对应租户接口 
export const tenantCheck = (params) => { return req("get", "/tenant/check?&phone=" + params, params) };

// // 获取用户菜单
// export const menu = (params) => { return axios.get("/api/menu?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// // 退出接口
// export const loginout = () => { return axios.delete("/api/login?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

/**
 * 用户管理 
 **/
// 用户管理-获取用户列表

export const getUserInfoByToken = (params) => { return req("get", "/user/get_by_token?token=" + params.token, params) };
export const createUserReq = (params) => { return req("post", "/user/create", params) };
export const updateUserReq = (params) => { return req("put", "/user/update", params) };
export const deleteUserReq = (params) => { return req("delete", "/user", params) };
export const updatePasswordUserReq = (params) => { return req("put", "/user/password", params) };
//系统用户管理
export const updateSystemUserReq = (params) => { return req("put", "/system/update_password", params) };

//报表相关

export const getGeneralReportReq = (params) => {
    return req("get", "/report/get_general_report?" +
        "endDate=" + params.endDate +
        "&startDate=" + params.startDate +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};





export const userList = (params) => { return req("post", "/api/User/list", params) };
// 用户管理-保存（添加编辑）
export const userSave = (params) => { return req("post", "/api/User/save", params) };
// 用户管理-删除用户
export const userDelete = (params) => { return axios.delete("/api/User/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// 用户管理-重置密码
export const userPwd = (params) => { return req("post", "/api/User/pwd", params) };
// 用户管理-修改状态
export const userLock = (params) => { return axios.get("/api/User/lock?userId=" + params.userId + "&lock=" + params.lock + "&token=" + localStorage.getItem('logintoken')) };
// 用户管理-数据权限
export const UserDeptTree = (params) => { return axios.get("/api/UserDept/tree/" + params + "?token=" + localStorage.getItem('logintoken')) };
// 用户管理-数据权限保存
export const UserDeptSave = (params) => { return req("post", "/api/UserDept/save", params) };
// 用户管理-获取部门设置
export const UserDeptdeptTree = (params) => { return axios.get("/api/UserDept/deptTree/" + params + "?token=" + localStorage.getItem('logintoken')) };
// 用户管理-保存部门设置
export const UserChangeDept = (params) => { return req("post", "/api/User/change/dept", params) };
// 用户管理-用户下线
export const userExpireToken = (params) => { return req("get", "/api/User/expireToken/" + params, {}) };
// 用户管理-刷新缓存
export const userFlashCache = (params) => { return req("get", "/api/User/flashCache/" + params, {}) };


/**
 * 菜单管理
 **/
// 菜单管理-获取菜单Module/list
export const ModuleList = () => { return req("post", "/api/Module/list") };
// 菜单管理-根据菜单获取数据
export const ModuleGet = (params) => { return axios.get("/api/Module/get/" + params + "?token=" + localStorage.getItem('logintoken')) };
// 菜单管理-获取父级菜单Module/nodes
export const ModuleNodes = (params) => { return req("post", "/api/Module/nodes", params) };
// 菜单管理-修改菜单
export const ModuleSave = (params) => { return req("post", "/api/Module/save", params) };
// 菜单管理-删除菜单
export const ModuleDelete = (params) => { return axios.delete("/api/Module/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

/**
 * 角色管理 
 **/
// 角色管理-获取角色列表
export const roleList = (params) => { return req("post", "/api/Role/list", params) };
// 角色管理-保存（添加编辑）
export const roleSave = (params) => { return req("post", "/api/Role/save", params) };
// 角色管理-删除角色
export const roleDelete = (params) => { return axios.delete("/api/Role/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// 角色管理-菜单权限（获取）
export const RoleRightTree = (params) => { return axios.get("/api/RoleRight/tree/" + params + "?token=" + localStorage.getItem('logintoken')) };
// 角色管理-菜单权限（保存）
export const RoleRightSave = (params) => { return req("post", "/api/RoleRight/save", params) };

/**
 * 
 *租户管理
 */
//新增租户
export const tenantSave = (params) => { return req("post", "/tenant", params) };

export const getTenantListReq = (params) => { return req("GET", "/tenant/getAll", params) };

export const changeTenantIsEnable = (params) => { return req("put", "/tenant/change_enable", params) };
export const deleteTenant = (params) => { return req("delete", "/tenant", params) };
export const resetTenant = (params) => { return req("put", "/tenant/password", params) };
export const searchTenant = (params) => { return req("post", "/tenant/search", params) };

export const updateTenantPassword = (params) => { return req("put", "/tenant/update_password", params) };


/**
 * 用户管理请求
 */

export const searchUser = (params) => {
    return req("get", "/user/search?userId=" + params.userId +
        "&username=" + params.username +
        "&phone=" + params.phone +
        "&endDate=" + params.endDate +
        "&beginDate=" + params.beginDate +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize +
        "&isEnable=" + params.isEnable, params)
};

export const changeUserIsEnable = (params) => {
    return req("put", "/user/change_enable?userId=" + params.userId + "&isEnable=" + params.isEnable, params)
};

/**
 * 场景相关请求
 */

export const getSceneListReq = (params) => {
    return req("get", "/scene/search?id" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize +
        "&classId=" + params.classId +
        "&name=" + params.name, params)
};

export const getSceneClassListReq = (params) => {
    return req("get", "/scene_class/getAll", params)
};

export const addSceneReq = (params) => {
    return req("post", "/scene/create", params)
};

export const updateSceneReq = (params) => {
    return req("put", "/scene/update", params)
};

export const deleteSceneReq = (params) => {
    return req("delete", "/scene/delete", params)
};

export const getSceneClassByCondition = (params) => {
    return req("get", "/scene_class/search?page=" + params.page +
        "&pageSize=" + params.pageSize +
        "&sceneClassName=" + params.sceneClassName +
        "&sceneClassId=" + params.sceneClassId, params)
};

export const getDefaultSceneListReq = (params) => {
    return req("get", "/scene/get_default_scene", params)
};
/**
 *系统配置相关 
 */

export const getSettingReq = (params) => {
    return req("get", "/setting/get_default_setting", params)
};

export const updateSettingReq = (params) => {
    return req("put", "/setting/update", params)

}
//场景分类相关
export const addSceneClassReq = (params) => {
    return req("post", "/scene_class/create", params)
};
export const updateSceneClassReq = (params) => {
    return req("put", "/scene_class/update", params)

}
export const deleteSceneClassReq = (params) => {
    return req("delete", "/scene_class/delete", params)
};


//report 记录相关

export const getPracticeRecordReq = (params) => {
    return req("get", "/report/get_by_condition?id=" + params.id +
        "&userName=" + params.userName +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize +
        "&startDate=" + params.startDate +
        "&endDate=" + params.endDate +
        "&userId=" + params.userId +
        "&sceneId=" + params.sceneId, params)
};

export const getMonthRecordReq = (params) => {
    return req("get", "/report/get_month_report?" +
        "&userId=" + params.userId +
        "&userName=" + params.userName +
        "&startDate=" + params.startDate +
        "&endDate=" + params.endDate, params)
};

export const getDayRecordReq = (params) => {
    return req("get", "/report/get_day_report?" +
        "&userId=" + params.userId +
        "&userName=" + params.userName +
        "&startDate=" + params.startDate +
        "&endDate=" + params.endDate, params)
};

export const getMonthFreqRecordReq = (params) => {
    return req("get", "/report/get_month_freq?" +
        "&startDate=" + params.startDate +
        "&endDate=" + params.endDate, params)
};
export const getDayFreqRecordReq = (params) => {
    return req("get", "/report/get_day_freq?" +
        "&startDate=" + params.startDate +
        "&endDate=" + params.endDate, params)
};


//问题反馈

export const getSuggestionReq = (params) => {
    return req("get", "/suggestion/get_by_condition?" +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize +
        "&userName=" + params.userName +
        "&tenantName=" + params.tenantName +
        "&beginDate=" + params.beginDate +
        "&endDate=" + params.endDate, params)
};


/**
 * 公司管理 
 **/
// 公司管理-获取公司列表
export const deptList = (params) => { return req("post", "/api/Dept/list", params) };
// 公司管理-保存（添加编辑）
export const deptSave = (params) => { return req("post", "/api/Dept/save", params) };
// 公司管理-删除公司
export const deptDelete = (params) => { return axios.get("/api/Dept/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

/**
 * 系统环境变量 
 **/
// 系统环境变量-获取系统环境变量列表
export const variableList = (params) => { return req("post", "/api/Variable/list", params) };
// 系统环境变量-保存（添加编辑）
export const variableSave = (params) => { return req("post", "/api/Variable/save", params) };
// 系统环境变量-删除系统环境变量
export const variableDelete = (params) => { return axios.delete("/api/Variable/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

/**
 * 权限管理 
 **/
// 权限管理-获取权限列表
export const permissionList = (params) => { return req("post", "/api/Permission/list", params) };
// 权限管理-保存权限
export const ermissionSave = (params) => { return req("post", "/api/Permission/save", params) };
// 权限管理-删除权限
export const ermissionDelete = (params) => { return axios.delete("/api/Permission/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// 权限管理-获取权限
export const roleDropDown = () => { return axios.get("/api/Role/dropDown/all?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// 权限管理-配置权限
export const RolePermission = (params) => { return req("post", "/api/RolePermission/save", params) };