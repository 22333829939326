/**
* 基础菜单 商品管理
*/
<template>
    <div v-loading.fullscreen.lock="loading">
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ formInline.userName + "日训练频率报表" }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="formInline" class="user-search">
            <el-form-item label="搜索：">
                <el-date-picker v-model="formInline.date" @change="dateChange" size="small" type="daterange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>

            </el-form-item>
        </el-form>

        <div style="width: 98%;height: 500px;" ref="myChart"></div>





    </div>
</template>

<script>
import { getDayFreqRecordReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
import { timestampToDate } from '../../utils/util'


export default {
    data() {
        return {
            loading: false, //是显示加载

            formInline: {
                userName: '',
                startDate: '',
                endDate: '',
                userId: '',
                date: []
            },
            seletedata: {
                ids: '',
                token: localStorage.getItem('logintoken')
            },
            userparm: [], //搜索权限
            listData: [], //用户数据
            // 分页参数
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 10

            },
            sceneClassList: [],
            timer: null,
            chart: null,
            option: {
                title: {
                    text: '训练次数'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Email']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    name: '时间',
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Email',
                        type: 'line',
                        stack: 'Total',
                        data: []
                    }
                ]
            }


        }
    },
    // 注册组件
    components: {
        Pagination
    },

    /**
     * 数据发生改变
     */

    /**
     * 创建完毕
     */
    created() {
        this.role = localStorage.getItem('role')
    },
    mounted() {
        console.log('参数', this.$route.query)
        if (this.$route.query.userName) {
            this.formInline.userName = this.$route.query.userName
        }
        if (this.$route.query.userId) {
            this.formInline.userId = this.$route.query.userId
        }
        this.initDate()

        this.search()



    },

    /**
     * 里面的方法只有被调用才会执行
     */
    methods: {
        // 获取公司列表

        dateChange() {
            if (this.formInline.date) {
                this.formInline.startDate = this.formInline.date[0]
                this.formInline.endDate = this.formInline.date[1]
            } else {
                this.formInline.startDate = ''
                this.formInline.endDate = ''
            }
        },
        // 搜索事件
        async search() {

            let res = await getDayFreqRecordReq(this.formInline)
            if (res.code === 200) {
                this.option.legend.data[0] = this.formInline.userName
                this.option.series[0].name = this.formInline.userName

                this.option.series[0].data = []
                this.option.xAxis.data = []
                for (let item of res.data) {
                    this.option.series[0].data.push(item.count)
                    this.option.xAxis.data.push(item.day)
                }
            }
            this.initChart()

            console.log("search", res, this.option)
            // this.getdata(this.formInline)
            // 分页赋值
        },

        reset() {
            this.formInline.userName = ''
            this.formInline.sceneId = ''
            this.formInline.id = ''
            this.formInline.page = 1
            this.formInline.pageSize = 10
            this.formInline.date = []
            this.formInline.startDate = ''
            this.formInline.endDate = ''
        },


        timeformat(time) {
            return time.slice(0, 10)
        },
        initDate() {
            let endDate = new Date();
            this.formInline.startDate = timestampToDate(endDate.getTime() - 14 * 24 * 60 * 60 * 1000) // "2023-07-19"  
            this.formInline.endDate = timestampToDate(endDate) // "2023-07-26"（假设今天是2023-07-19）  
        },

        initChart() {
            console.log("initChart", this.$refs.myChart)
            this.chart = this.$echarts.init(this.$refs.myChart)
            this.chart.setOption(this.option)
        },

        startTimer() {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.loading = false
            }, 1200)
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>

<style scoped>
.user-search {
    margin-top: 20px;
}

.userRole {
    width: 100%;
}

.avatar-container {
    display: flex;
    line-height: 80px;
    margin-bottom: 10px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}

.avatar-container {
    display: flex;
    line-height: 80px;
    margin-bottom: 10px;
}

.avatar-uploader-border {
    border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
    border-color: #409EFF;
}

.avatar {
    width: 80px;
    height: 80px;
    display: block;
}

.width-320px {
    width: 320px;
}

.avatar-uploader {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;
}
</style>