/**
* 头部菜单
*/
<template>
  <el-menu class="el-menu-demo" mode="horizontal" background-color="#051f24" text-color="#fff" active-text-color="#fff">
    <el-button class="buttonimg">
      <img class="showimg" :src="collapsed ? imgsq : imgshow" @click="toggle(collapsed)">
    </el-button>
    <el-submenu index="2" class="submenu">
      <!-- <template slot="title">{{user.userRealName}}</template> -->
      <template slot="title">{{ userInfo }}</template>

      <router-link to='/system/UserConfig'> <el-menu-item index="2-1"> 修改密码</el-menu-item></router-link>
      <!-- <el-menu-item @click="content()" index="2-2">个人中心</el-menu-item> -->
      <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { logout, getUserInfoByToken } from '../api/userMG'
import router from '../router'
export default {
  name: 'navcon',
  data() {
    return {
      collapsed: true,
      imgshow: require('../assets/img/show.png'),
      imgsq: require('../assets/img/sq.png'),
      user: {},
      userInfo: ''
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // this.user = JSON.parse(localStorage.getItem('userdata'))
  },
  mounted() {
    this.getUserInfo()

  },
  methods: {
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const accessToken = localStorage.getItem('accessToken');
          localStorage.setItem("userInfo", '')
          router.push({ path: '/login' })
          let res = await logout({ token: accessToken })
          console.log("登出", res)
          localStorage.setItem("accessToken", '')
          localStorage.setItem("refreshToken", '')
          localStorage.setItem("userdata", '')


          // setTimeout(() => {
          //   this.$store.commit('logout', 'false')
          //   this.$router.push({ path: '/login' })
          //   this.$message({
          //     type: 'success',
          //     message: '已退出登录!'
          //   })
          // }, 1000)
          // loginout()
          //   .then(res => {
          //     if (res.success) {
          //       //如果请求成功就让他2秒跳转路由
          //       setTimeout(() => {
          //         this.$store.commit('logout', 'false')
          //         this.$router.push({ path: '/login' })
          //         this.$message({
          //           type: 'success',
          //           message: '已退出登录!'
          //         })
          //       }, 1000)
          //     } else {
          //       this.$message.error(res.msg)
          //       this.logining = false
          //       return false
          //     }
          //   })
          //   .catch(err => {
          //     // 获取图形验证码
          //     this.getcode()
          //     this.logining = false
          //     this.$message.error('退出失败，请稍后再试！')
          //   })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    setting() {
      router.push({ path: 'system/UserConfig' })
    },
    // 切换显示
    toggle(showtype) {
      this.collapsed = !showtype
      this.$root.Bus.$emit('toggle', this.collapsed)
    },
    async getUserInfo() {
      let role = localStorage.getItem('role')
      if (role === 'tenant') {
        let res = await getUserInfoByToken({ token: localStorage.getItem('accessToken') })
        console.log("获取用户信息", res)
        if (res.code === 200) {
          this.userInfo = res.data.tenantName
        } else {
          this.$message.error(res.message)
        }
      } else {
        this.userInfo = '超级管理员'
      }
    }

  }
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}

.submenu {
  float: right;
}

.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
}

.showimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}

.showimg:active {
  border: none;
}
</style>