/**
* 基础菜单 商品管理
*/
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="搜索：">
        <el-input size="small" v-model="formInline.username" placeholder="输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input size="small" v-model="formInline.phone" placeholder="输入手机号码"></el-input>
      </el-form-item>
      <!-- <el-form-item label="">
        <el-date-picker v-model="formInline.searchDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>
        <el-button v-if="role === 'tenant'" size="small" type="primary" icon="el-icon-plus"
          @click="handleEdit()">添加</el-button>

      </el-form-item>
    </el-form>

    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 80%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" min-width="80">
      </el-table-column>
      <el-table-column prop="username" label="用户名" min-width="100">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="100">
      </el-table-column>
      <el-table-column prop="tenantName" label="所属租户" min-width="200">
      </el-table-column>
      <!-- <el-table-column prop="openId" label="openid" min-width="100">
      </el-table-column> -->
      <el-table-column label="是否启用" align="center" :filters="enableFilter" :filter-method="filterTag"
        filter-placement="bottom-end" min-width="70">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949"
            @change="handleSwitchChange(scope.row.id, $event)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="注册时间" min-width="80">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="300">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button>
          <el-button size="mini" type="info" @click="resetUser(scope.$index, scope.row)">重置</el-button>
          <el-button size="mini" type="primary" @click="searchHandle(scope.$index, scope.row)">训练记录</el-button>

          <!-- <el-button size="mini" type="primary" @click="showDetail(scope.$index, scope.row)">详情</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog"
      :close-on-click-modal="false">
      <!--我这个需求是有消息时才会让这个功能显示，所以使用了v-if-->


      <div class="dialog">
        <el-form label-width="70px" :model="editForm" :rules="rules" ref="editForm">
          <el-form-item label="用户名" prop="username" style="width: 300px;">
            <el-input size="small" v-model="editForm.username" auto-complete="off" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone" style="width: 300px;">
            <el-input type="phone" size="small" v-model="editForm.phone" auto-complete="off" placeholder="请输入联系电话"
              @input="filterInput"></el-input>
          </el-form-item>


        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import router from '@/router'
import { searchUser, getTenantListReq, searchTenant, updatePasswordUserReq, deleteUserReq, updateUserReq, resetTenant, deleteTenant, changeUserIsEnable, tenantList, tenantSave, deptDelete, createUserReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
export default {
  data() {
    return {
      enableFilter: [{ text: '启用', value: true }, { text: '禁用', value: false }],
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',
      editForm: {
        username: '',
        phone: '',
        id: ''
      },
      // rules表单验证
      rules: {
        tenantName: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ],
        administratorPhone: [{ required: true, message: '请输入联系人号码', trigger: 'blur' }],
        administratorName: [{ required: true, message: '请输入租户姓名', trigger: 'blur' }]
      },
      formInline: {
        page: 1,
        pageSize: 10,
        username: '',
        userId: '',
        phone: '',
        //tenantId: null,
        searchDate: '',
        beginDate: '',
        endDate: '',
        isEnable: ''
        // token: localStorage.getItem('logintoken')
      },
      // 删除部门
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      },
      tenantList: [],
      role: ''
    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.role = localStorage.getItem('role')
    this.getdata()
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata() {
      //this.loading = true
      await this.getTenantList()
      this.getUserList()

    },

    getUserList() {
      this.search()
    },

    async getTenantList() {
      let res = await getTenantListReq()
      console.log('getTenantListReq', res)

      if (res.code === 200) {
        this.tenantList = []
        for (let item of res.data) {
          this.tenantList.push({ tenantId: item.id, tenantName: item.tenantName })
        }
      } else {
        this.$message.error("获取租户信息失败！")
      }

    },


    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    async search() {

      if (this.formInline.searchDate) {
        this.formInline.beginDate = this.formInline.searchDate[0]
        this.formInline.endDate = this.formInline.searchDate[1]
      }
      let res = await searchUser(this.formInline)
      console.log('searchUser', res)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          item.tenantName = this.tenantList.find(tenant => tenant.tenantId == item.tenantId).tenantName
          if (item.isEnable == 1) {
            item.enable = true
          } else {
            item.enable = false
          }
        }

        console.log("this.listData", this.listData)

        // 分页赋值
        this.pageparm.currentPage = this.formInline.page
        this.pageparm.pageSize = this.formInline.limit
        this.pageparm.total = res.data.total
      }
    },
    reset() {
      this.formInline.username = ''
      this.formInline.userId = ''
      this.formInline.phone = ''
      this.formInline.searchDate = ''
      this.formInline.endDate = ''
      this.formInline.beginDate = ''
      this.formInline.page = 1
      this.formInline.pageSize = 10
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      console.log("handleEdit", index, row)
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '编辑用户'
        this.editForm.id = row.id
        this.editForm.username = row.username
        this.editForm.phone = row.phone
      } else {
        this.title = '添加用户'
        this.editForm.id = ''
        this.editForm.username = ''
        this.editForm.phone = ''
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        console.log("editForm **", this.editForm, valid)
        let res = null
        if (valid) {
          if (this.editForm.id) {
            res = await updateUserReq(this.editForm)
          } else {
            res = await createUserReq(this.editForm)
          }
          this.editFormVisible = false
          this.loading = false
          if (res.code === 200) {
            this.getdata(this.formInline)
            this.$message({
              type: 'success',
              message: this.editForm.id ? '更新成功' : '添加成功！'
            })
          } else if (res.code === 518) {
            this.$notify.error({
              title: '添加失败',
              message: '该手机号已在其他店注册，如需注册请联系管理员',
              duration: 0
            });
            this.$message({
              type: 'error',
              message: res.message
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }

        } else {
          return false
        }
      })
    },
    // 删除公司
    deleteUser(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUserReq({ id: row.id })
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getdata(this.formInline)
              } else {
                this.$message({
                  type: 'info',
                  message: res.message
                })
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('租户删除失败，请稍后再试！')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    resetUser(index, row) {
      this.$confirm('确定要重置租户密码吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          updatePasswordUserReq({ id: row.id, password: row.phone })
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '重置密码成功!'
                })
                this.getdata(this.formInline)
              } else {
                this.$message({
                  type: 'info',
                  message: res.message
                })
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('重置密码失败，请稍后再试！')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          })
        })
    },
    filterTag(value, row) {
      return row.enable === value
    },
    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
    },
    timeformat(time) {
      return time.slice(0, 10)
    },
    async handleSwitchChange(id, isEnable) {
      console.log('handleSwitchChange', id, isEnable)
      let res = await changeUserIsEnable({ id: id, isEnable: isEnable ? 1 : 0 })
      console.log("res", res)
      if (res.code === 200) {
        this.getUserList()
        this.$message({
          type: isEnable ? 'success' : 'warning',
          message: isEnable ? '启用成功！' : '禁用成功！'
        })
      } else {
        this.$message({
          type: 'error',
          message: '修改失败！' + res.message
        })
      }
    },
    filterInput(value) {
      this.editForm.phone = value.replace(/\D/g, ''); // \D 匹配任何非数字字符  
    },
    searchHandle(index, row) {
      router.push({
        path: '/record/PracticeRecord',
        query: {
          userId: row.id
        }
      })
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>