/**
* 基础菜单 商品管理
*/
<template>
    <div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>场景分类列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索筛选 -->
        <el-form :inline="true" :model="formInline" class="user-search">

            <el-form-item label="搜索：">
                <el-input size="small" v-model="formInline.sceneClassName" placeholder="输入场景分类名称"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>
                <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>

            </el-form-item>
        </el-form>

        <!--列表-->
        <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
            element-loading-text="拼命加载中" style="width: 60%;">
            <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
            <el-table-column sortable type="index" label="序号" align="center" min-width="80">
            </el-table-column>
            <el-table-column prop="imagePath" label="场景图标" align="center" min-width="60">
                <template slot-scope="scope">
                    <el-image :src="scope.row.imagePath" style="height: 50px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="className" align="center" label="场景分类名称" min-width="80">
            </el-table-column>


            <el-table-column align="center" label="操作" min-width="100">
                <template slot-scope="scope">
                    <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="deleteScene(scope.$index, scope.row)">删除</el-button>
                    <!-- <el-button size="mini" type="info" @click="resetUser(scope.$index, scope.row)">重置</el-button> -->
                    <!-- <el-button size="mini" type="primary" @click="showDetail(scope.$index, scope.row)">详情</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <!-- 编辑界面 -->
        <el-dialog :title="title" :visible.sync="editFormVisible" width="650px" @click="closeDialog"
            :close-on-click-modal="false">
            <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
                <div class="avatar-container" style="margin-right: 160px;">上传图片&nbsp;&nbsp;&nbsp;&nbsp;
                    <img v-if="editForm.imagePath" :src="editForm.imagePath" class="avatar m-r-10"
                        @click="deleteImage()">
                    <el-upload v-if="!editForm.imagePath" class="avatar-uploader avatar-uploader-border"
                        :action="uploadImagePath" :show-file-list="false" :headers="headers"
                        :on-success="handleAvatarSuccess">

                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <el-form label-width="78px" :model="editForm" :rules="rules" ref="editForm" style="width: 320px;">
                    <el-form-item label="场景分类" prop="className" class="width-320px">
                        <el-input size="small" v-model="editForm.className" auto-complete="off"
                            placeholder="请输入场景分类名称"></el-input>
                    </el-form-item>


                </el-form>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeDialog">取消</el-button>
                <el-button size="small" type="primary" :loading="loading" class="title"
                    @click="submitForm('editForm')">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getSceneClassByCondition, addSceneClassReq, updateSceneClassReq, deleteSceneClassReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
import { getUploadImagePath } from '../../utils/util'

export default {
    data() {
        return {
            enableFilter: [{ text: '启用', value: true }, { text: '禁用', value: false }],
            nshow: true, //switch开启
            fshow: false, //switch关闭
            loading: false, //是显示加载
            editFormVisible: false, //控制编辑页面显示与隐藏
            title: '添加',
            uploadFilePath: '',
            uploadImagePath: '',
            editForm: {
                className: '',
                imagePath: '',
                id: '',
            },
            // rules表单验证
            rules: {
                className: [{ required: true, message: '请输入场景分类名称', trigger: 'blur' }],
            },
            formInline: {
                page: 1,
                pageSize: 10,
                sceneClassName: '',
                sceneClassId: '',
                // token: localStorage.getItem('logintoken')
            },
            // 删除部门
            seletedata: {
                ids: '',
                token: localStorage.getItem('logintoken')
            },
            userparm: [], //搜索权限
            listData: [], //用户数据
            // 分页参数
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 10
            },
            sceneClassList: []
        }
    },
    // 注册组件
    components: {
        Pagination
    },

    /**
     * 数据发生改变
     */

    /**
     * 创建完毕
     */
    created() {
        this.getdata()
    },
    mounted() {
        this.uploadImagePath = getUploadImagePath()
    },
    computed: {
        headers() {
            return {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('accessToken')
            }
        }
    },


    /**
     * 里面的方法只有被调用才会执行
     */
    methods: {
        // 获取公司列表
        async getdata() {

            this.search()
        },
        // 分页插件事件
        callFather(parm) {
            this.formInline.page = parm.currentPage
            this.formInline.limit = parm.pageSize
            this.getdata(this.formInline)
        },
        // 搜索事件
        async search() {


            let res = await getSceneClassByCondition(this.formInline)
            if (res.code === 200) {
                this.listData = res.data.records

            }
            console.log("search", res)
            // this.getdata(this.formInline)
            // 分页赋值
            this.pageparm.currentPage = this.formInline.page
            this.pageparm.pageSize = this.formInline.pageSize
            this.pageparm.total = res.data.total
        },
        async getSceneClassList() {
            let res = await getSceneClassListReq()
            console.log("getSceneClassList", res)

            if (res.code === 200) {
                this.sceneClassList = res.data
            } else {
                this.$message.error("获取场景分类信息失败！")
            }
        },
        reset() {
            this.formInline.sceneClassName = ''

            this.formInline.page = 1
            this.formInline.pageSize = 10
        },
        //显示编辑界面
        handleEdit: function (index, row) {
            console.log("handleEdit", index, row)
            this.editFormVisible = true
            if (row != undefined && row != 'undefined') {
                this.title = '编辑'
                this.editForm.id = row.id
                this.editForm.className = row.className
                this.editForm.imagePath = row.imagePath
            } else {
                this.title = '添加'
                this.resetEditForm()
            }
        },
        // 编辑、增加页面保存方法
        submitForm(editData) {
            this.$refs[editData].validate(async valid => {
                console.log("editForm **", this.editForm, valid)
                if (!this.editForm.imagePath) {
                    this.$message.error('请上传图片！')
                    return
                }


                if (valid) {
                    if (this.editForm.id) {
                        //编辑修改
                        let res = await updateSceneClassReq(this.editForm)
                        this.editFormVisible = false
                        this.loading = false
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: this.editForm.id ? '更新成功' : '添加成功！'
                            })
                            this.resetEditForm()
                        } else {
                            this.$message({
                                type: 'error',
                                message: '更新失败！' + res.message
                            })
                        }
                    } else {
                        //添加
                        let res = await addSceneClassReq(this.editForm)
                        this.editFormVisible = false
                        this.loading = false
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: this.editForm.id ? '更新成功' : '添加成功！'
                            })
                            this.resetEditForm()
                        } else {
                            this.$message({
                                type: 'error',
                                message: '添加失败！' + res.message
                            })
                        }

                    }

                    this.search()


                } else {
                    return false
                }
            })
        },
        handleAvatarSuccess(response) {
            if (response.code === 200) {
                this.editForm.imagePath = response.data
                this.$message({
                    type: 'success',
                    message: '图片上传成功！'
                })
            }
        },
        // 删除公司
        deleteScene(index, row) {
            this.$confirm('确定要删除吗?', '信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    let res = await deleteSceneClassReq({ id: row.id })
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        this.search()
                    } else if (res.code === 513) {
                        this.$message({ type: 'error', message: '删除失败！场景分类下存在相应场景，不能删除' })
                    } else {
                        this.$message({ type: 'error', message: res.message })
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        resetUser(index, row) {
            this.$confirm('确定要重置租户密码吗?', '信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    resetTenant({ id: row.id, password: row.administratorPhone })
                        .then(res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '重置密码成功!'
                                })
                                this.getdata(this.formInline)
                            } else {
                                this.$message({
                                    type: 'info',
                                    message: res.message
                                })
                            }
                        })
                        .catch(err => {
                            this.loading = false
                            this.$message.error('重置密码失败，请稍后再试！')
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消重置密码'
                    })
                })
        },
        filterTag(value, row) {
            return row.isEnable === value
        },
        // 关闭编辑、增加弹出框
        closeDialog() {
            this.editFormVisible = false
            this.resetEditForm()
        },
        timeformat(time) {
            return time.slice(0, 10)
        },
        resetEditForm() {
            this.editForm = {
                name: '',
                sceneDescription: '',
                sceneTarget: '',
                example: '',
                imagePath: '',
                classId: '',
                id: '',
            }
        },
        deleteImage() {
            console.log("点击删除图片")
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.editForm.imagePath = ''
            }).catch(() => {

            });
        }

    }
}
</script>

<style scoped>
.user-search {
    margin-top: 20px;
}

.userRole {
    width: 100%;
}

.avatar-container {
    display: flex;
    line-height: 80px;
    margin-bottom: 10px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}

.avatar-container {
    display: flex;
    line-height: 80px;
    margin-bottom: 10px;
}

.avatar-uploader-border {
    border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
    border-color: #409EFF;
}

.avatar {
    width: 80px;
    height: 80px;
    display: block;
}

.width-320px {
    width: 320px;
}

.avatar-uploader {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;
}
</style>