class RequestQueue {
    constructor() {
        this.queue = [];
        console.log("RequestQueue created.")
    }

    // 添加请求到队列  
    enqueue(request) {
        this.queue.push(request);
        // this.processQueue();
    }
    popQueue() {
        if (this.queue.length === 0) {
            return
        }
        this.queue.shift()
    }

    // 从队列中取出并执行请求  
    async processQueue() {
        if (this.queue.length === 0) {
            return null;
        }
        console.log('currentRequest queue', this.queue)

        const currentRequest = this.queue[0];
        //const currentRequest = this.queue[0]

        console.log('currentRequest', currentRequest, this.queue)
        try {
            // const response = await axios(currentRequest);
            //  const response = await currentRequest
            // 处理响应或执行其他操作  
            // console.log(response.data);
            return currentRequest.then(res => res.data);

        } catch (error) {
            // 处理错误  
            console.error(error);
        } finally {
            // 无论成功还是失败，都继续处理队列中的下一个请求  
            // this.processQueue();
        }
    }
}

export default new RequestQueue();