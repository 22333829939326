/**
* 基础菜单 商品管理
*/
<template>
  <div v-loading.fullscreen.lock="loading">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户总训练报表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="搜索：">

        <el-date-picker v-model="formInline.date" @change="dateChange" size="small" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>

      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table v-show="!loading" size="small" :data="listData" highlight-current-row border style="width: 70%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" align="center" min-width="80">
      </el-table-column>
      <el-table-column prop="userName" align="center" label="练习人姓名" min-width="100">
      </el-table-column>
      <el-table-column prop="count" align="center" label="练习次数" min-width="100">
      </el-table-column>
      <el-table-column prop="maxScore" align="center" label="最高得分" min-width="100">
      </el-table-column>
      <el-table-column prop="minScore" align="center" label="最低得分" min-width="100">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">总报表</el-button>
          <el-button size="mini" type="success" @click="toMonthHandle(scope.$index, scope.row)">月报表</el-button>
          <el-button size="mini" type="warning" @click="toDayHandle(scope.$index, scope.row)">日报表</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->






  </div>
</template>

<script>
import router from '@/router'
import { getGeneralReportReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
import { timestampToHHSS } from '../../utils/util'
export default {
  data() {
    return {
      loading: false, //是显示加载

      formInline: {
        page: 1,
        pageSize: 10,
        userName: '',
        startDate: '',
        endDate: '',
        sceneId: '',
        id: '',
        date: []
      },
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10

      },
      sceneClassList: [],
      timer: null,
    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.role = localStorage.getItem('role')
  },
  mounted() {
    console.log('参数', this.$route.query)
    if (this.$route.query.sceneId) {
      this.formInline.sceneId = this.$route.query.sceneId
    }
    this.search()

  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.pageSize = parm.pageSize
      this.search(this.formInline)
    },
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }
    },
    // 搜索事件
    async search() {


      let res = await getGeneralReportReq(this.formInline)
      if (res.code === 200) {
        this.listData = res.data.data
        this.pageparm.currentPage = this.formInline.page
        this.pageparm.pageSize = this.formInline.pageSize
        this.pageparm.total = res.data.count
      }
      console.log("search", res)
      // this.getdata(this.formInline)
      // 分页赋值
    },

    reset() {
      this.formInline.userName = ''
      this.formInline.sceneId = ''
      this.formInline.id = ''
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.date = []
      this.formInline.startDate = ''
      this.formInline.endDate = ''
    },


    timeformat(time) {
      return time.slice(0, 10)
    },



    startTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.loading = false
      }, 1200)
    },
    toMonthHandle(index, row) {
      router.push({
        path: '/report/MonthReport',
        query: {
          userId: row.userId,
          userName: row.userName
        }
      })
    },
    toDayHandle(index, row) {
      router.push({
        path: '/report/DayReport',
        query: {
          userId: row.userId,
          userName: row.userName
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.width-320px {
  width: 320px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
</style>