import axios from 'axios';
import { refreshToken } from './refreshToken';
//引入路由
import router from '../router'
import { setAccessToken, setRefreshToken } from './token'
import RequestQueue from './RequestQueen';
import service from './service';

// 通用公用方法
const req = async (method, url, params) => {
    // 根据请求类型设置Content-Type
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        //  Authorization: localStorage.getItem('accessToken')
    };

    // 如果params是对象，则需要转换为x-www-form-urlencoded格式
    if (typeof params === 'object') {
        headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        params = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    } else {
        // 如果params是字符串，则保持原样
        headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    return service({
        method: method,
        url: url,
        headers: headers,
        data: params,
        // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
    }).then(res => {
        console.log("请求结果", res)
        return res ? res.data : null
    });
};

// 登录请求方法
const loginreq = async (method, url, params) => {
    console.log("process.env.VUE_APP_BASE_URL", baseURL)
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
        traditional: true,
        transformRequest: [
            function (data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};


export {
    loginreq,
    req
}



// const service = axios.create({
//     baseURL: '',
//     timeout: 50000
// });

// let task = [];

// let callbacks = []; // 失效后同时发送请求的容器 -- 缓存接口

// // 刷新 token 后, 将缓存的接口重新请求一次
// function onAccessTokenFetched(newToken) {
//     callbacks.forEach(callback => {
//         callback(newToken);
//     });
//     // 清空缓存接口
//     callbacks = [];
// }
// // 添加缓存接口
// function addCallbacks(callback) {
//     callbacks.push(callback);
// }

// //  包装参数对象方法
// function handleParams(data) {
//     return qs.stringify(data);
// }


// // 请求拦截器
// service.interceptors.request.use(
//     config => {
//         console.log("请求拦截器", config)

//         // 在发送请求之前做些什么
//         // 例如添加请求头
//         const accessToken = localStorage.getItem('accessToken');
//         if (accessToken) {
//             // config.headers.Authorization = `Bearer ${accessToken}`; // 假设你的token是Bearer类型
//             config.headers.Authorization = `${accessToken}`; //
//         }
//         // 如果需要的话，还可以在这里添加其他请求头或处理逻辑
//         return config;
//     },
//     error => {
//         // 对请求错误做些什么
//         return Promise.reject(error);
//     }
// );

// // 响应拦截器
// service.interceptors.response.use(
//     async response => {
//         // 对响应数据做点什么
//         console.log("响应拦截器", response)
//         if (response.data && response.data.code === 503) {
//             if (response.data.data && response.data.data.isRefreshToken) {
//                 //返回登录页
//                 localStorage.setItem("userInfo", '')
//                 localStorage.setItem("accessToken", '')
//                 localStorage.setItem("refreshToken", '')

//                 router.push({ path: '/login' })
//                 console.log("token刷新失败，返回登录页")
//                 return
//             } else {
//                 console.log("开始刷新token", response)
//                 debugger
//                 const retryOriginalRequest = new Promise(async resolve => {
//                     addCallbacks(newToken => {
//                         // console.log(newToken)
//                         // console.log(config)
//                         // 表示用新的token去替换掉原来的token
//                         response.config.headers["Authorization"] = newToken;
//                         // 替换掉url -- 因为baseURL会扩展请求url
//                         // config.url = config.url.replace(apiUrl, '')
//                         resolve(service(response.config)); // 调用resolve请求队列里面接口
//                     });

//                     let res = await refreshToken()
//                     console.log("refreshToken===>", res)
//                     if (res.code === 200) {
//                         setAccessToken(res.data.accessToken)
//                         setRefreshToken(res.data.refreshToken)
//                         onAccessTokenFetched(res.data.accessToken);

//                     }
//                     console.log("retryOriginalRequest===>", retryOriginalRequest)
//                     return retryOriginalRequest;
//                 });
//                 //     // 无感刷新Token
//                 //     if (!isRefreshing) {
//                 //         isRefreshing = true;
//                 //         refreshToken()
//                 //             .then(res => {
//                 //                 // console.log(res)
//                 //                 // 用rftoken获取新的token
//                 //                 const newToken = res.data.access_token;
//                 //                 const newRefreshToken = res.data.refresh_token;
//                 //                 util.cookies.set("token", newToken);
//                 //                 util.cookies.set("refresh_token", newRefreshToken);
//                 //                 // setToken()
//                 //                 // setRefreshToken()
//                 //             })
//                 //             .catch(res => {
//                 //                 // console.log(res)
//                 //                 // 刷新token报错的话, 就需要跳转到登录页面
//                 //                 // setToken('')
//                 //                 // setRefreshToken('')
//                 //                 Message({
//                 //                     message: "您长时间未操作系统,请重新登录" || "Error",
//                 //                     type: "error",
//                 //                     duration: 2 * 1000
//                 //                 });
//                 //                 errorLog(res);
//                 //                 // 删除cookie
//                 //                 util.cookies.remove("refresh_token");
//                 //                 util.cookies.remove("token");
//                 //                 util.cookies.remove("uuid");
//                 //                 util.cookies.remove("user-role");
//                 //                 util.cookies.remove("user-info");
//                 //                 util.cookies.remove("current-term");
//                 //                 // 跳转到登录页
//                 //                 window.location.href = `index.html#/index`;
//                 //             })
//                 //             .finally(() => {
//                 //                 isRefreshing = false;
//                 //             });
//                 //     }
//                 // });
//                 // // 将token过期期间请求的接口包装成promise返回，等待刷新token后重新请求
//                 // return retryOriginalRequest;





//                 // let reqService = null;
//                 // if (response) {
//                 //     let config = response.config
//                 //     task.push({ config })
//                 //     // return new Promise((resolve) => {
//                 //     //     task.push(
//                 //     //         {
//                 //     //             config,
//                 //     //             resolve
//                 //     //         }
//                 //     //     )
//                 //     // })
//                 // }
//                 // //刷新token
//                 // let res = await refreshToken()
//                 // if (res.code === 200) {
//                 //     console.log("token刷新 成功:", res)
//                 //     setAccessToken(res.data.accessToken)
//                 //     setRefreshToken(res.data.refreshToken)
//                 //     task.forEach(async (item) => {
//                 //         return service(item.config).then(res => {
//                 //             res.data
//                 //         })
//                 //     });
//                 // }
//                 // console.log("token刷新 res:", res)
//                 // return res
//             }
//         } else {
//             // RequestQueue.popQueue()
//             let responseUrl = response.request.responseURL

//             console.log("进入response:", response, responseUrl)
//             // console.log("进入response:RequestQueue", requestList)
//             // requestList = requestList.filter(item => {
//             //     console.log("item.url", item.url)
//             //     return item.url.split('api')[1] != responseUrl.split('api')[1]
//             // })
//             // requestList = requestList ? requestList : []
//             // console.log("*******************", requestList)
//             return response;
//         }
//     },
//     error => {
//         // 对响应错误做点什么
//         // 例如，你可以在这里统一处理错误码或错误信息
//         console.log("进入error:", error)

//         return Promise.reject(error);
//     }
// );



// // 登录请求方法
// const loginreq = (method, url, params) => {
//     return axios({
//         method: method,
//         url: url,
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         data: params,
//         traditional: true,
//         transformRequest: [
//             function (data) {
//                 let ret = ''
//                 for (let it in data) {
//                     ret +=
//                         encodeURIComponent(it) +
//                         '=' +
//                         encodeURIComponent(data[it]) +
//                         '&'
//                 }
//                 return ret
//             }
//         ]
//     }).then(res => res.data);
// };

// // 通用公用方法
// const req = (method, url, params) => {
//     // 根据请求类型设置Content-Type
//     let headers = {
//         'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
//         //  Authorization: localStorage.getItem('accessToken')
//     };

//     // 如果params是对象，则需要转换为x-www-form-urlencoded格式
//     if (typeof params === 'object') {
//         headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
//         params = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
//     } else {
//         // 如果params是字符串，则保持原样
//         headers['Content-Type'] = 'application/json;charset=utf-8';
//     }
//     // let reqService = service({
//     //     method: method,
//     //     url: url,
//     //     headers: headers,
//     //     data: params,
//     //     // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
//     // });
//     // console.log("reqService", url, reqService, typeof reqService)
//     // console.log("reqService url", url.split('/').find(item => item === 'token') ? 'true' : 'false')
//     // if (url.split('/').find(item => item === 'token')) {
//     //     return reqService.then(res => res.data);
//     // }
//     // requestList.push({ url: url, request: reqService })

//     //  console.log("serviceFromQueue", requestList)
//     //return RequestQueue.processQueue()
//     // return reqService.then(res => res.data);
//     //   return reqService.then(res => res.data);
//     return service({
//         method: method,
//         url: url,
//         headers: headers,
//         data: params,
//         // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
//     }).then(res => {
//         return res ? res.data : null
//     });
// };
// //     return service({
// //         method: method,
// //         url: url,
// //         headers: headers,
// //         data: params,
// //         // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
// //     }).then(res => res.data);
// // };



// // 通用公用方法
// // const req = (method, url, params) => {
// //     return axios({
// //         method: method,
// //         url: url,
// //         headers: {
// //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
// //             // 'Content-Type': 'application/json;charset=utf-8',
// //             Authorization: localStorage.getItem('accessToken')
// //         },
// //         data: params,
// //         traditional: true,
// //         transformRequest: [
// //             function (data) {
// //                 let ret = ''
// //                 for (let it in data) {
// //                     ret +=
// //                         encodeURIComponent(it) +
// //                         '=' +
// //                         encodeURIComponent(data[it]) +
// //                         '&'
// //                 }
// //                 return ret
// //             }
// //         ]
// //     }).then(res => res.data);
// // };

// export {
//     loginreq,
//     req
// }