import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        user: {}
    },
    mutations: {
        // 登录
        login(state, user) {
            state.user = user;
            localStorage.setItem("userInfo", user.userInfo);
            localStorage.setItem("role", user.role);

        },
        // 退出
        logout(state, user) {
            state.user = {};
            localStorage.setItem("userInfo", "");
        }
    },
    getters: {
        getSystemRole() {

            return localStorage.getItem("role")

        }
    }
})