<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;margin-bottom: 30px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="width: 100%; display: flex;justify-content: center;">
      <div
        style="width: 450px;height: 350px;margin-top: 30px;display: flex;flex-direction: column; justify-content: center;align-items: center;position: relative;"
        class="background">
        <div style="left: 20px;top: 10px;position: absolute;color: #909399;">修改密码</div>
        <el-form label-width="100px" class="demo-ruleForm" style="width: 350px;margin-right: 30px;margin-top: 10px;">
          <el-form-item label="原始密码" prop="password">
            <el-input v-model.number="ruleForm.password" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item label="修改密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" show-password></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submitForm('ruleForm')">提交修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { updateTenantPassword, updateSystemUserReq } from '../../api/userMG'
import { getAccessToken } from '../../api/token'
export default {
  data() {
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        password: ''
      },
      rules: {
        pass: [
          { trigger: 'blur' }
        ],
        checkPass: [
          { trigger: 'blur' }
        ],
        age: [
          { trigger: 'blur' }
        ]
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    async submitForm() {
      if (this.ruleForm.checkPass != '' && this.ruleForm.pass != '' && this.ruleForm.password != '') {
        if (this.ruleForm.pass === this.ruleForm.checkPass) {
          if (this.ruleForm.pass.length > 5) {
            let res = null
            if (localStorage.getItem("role") === "system") {

              res = await updateSystemUserReq({ oldPassword: this.ruleForm.password, newPassword: this.ruleForm.pass })

            } else if (localStorage.getItem("role") === "tenant") {
              res = await updateTenantPassword({ oldPassword: this.ruleForm.password, newPassword: this.ruleForm.pass })
            } else {
              this.$message.error('数据错误，重新登录')
              return
            }


            if (res.code === 200) {
              this.$message({
                message: '修改成功！',
                type: 'success'
              });
              this.ruleForm.pass = ''
              this.ruleForm.checkPass = ''
              this.ruleForm.password = ''
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              });
            }
          } else {
            this.$message.error('密码长度不能小于6位')
          }
        } else {
          this.$message.error('两次密码输入不一致')

        }
      } else {
        this.$message.error('请输入完整信息')
      }
    }

  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.background {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
</style>