/**
* 基础菜单 商品管理
*/
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>问题反馈</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="搜索：">
        <el-input size="small" v-model="formInline.tenantName" placeholder="输入门店名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input size="small" v-model="formInline.userName" placeholder="输入联系人姓名" clearable></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-date-picker v-model="formInline.searchDate" @change="dateChange" type="daterange" size="small"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 80%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" min-width="80" align="center">
      </el-table-column>
      <el-table-column prop="userName" label="反馈人" min-width="50" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="80" align="center">
      </el-table-column>
      <el-table-column prop="tenantName" label="门店名称" min-width="100" align="center">
      </el-table-column>

      <el-table-column prop="content" label="反馈内容" min-width="200" align="center" height="100px"
        :show-overflow-tooltip="true">
      </el-table-column>

      <el-table-column prop=" createTime" label="反馈时间" min-width="80" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToHHSS }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">查看内容</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog">
      <div>
        {{ currentSuggestion }}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSuggestionReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
export default {
  data() {
    return {
      enableFilter: [{ text: '启用', value: true }, { text: '禁用', value: false }],
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',
      editForm: {
        tenantAddress: '',
        tenantName: '',
        administratorPhone: '',
        administratorName: '',
        token: localStorage.getItem('logintoken')
      },
      // rules表单验证
      rules: {
        tenantName: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ],
        administratorPhone: [{ required: true, message: '请输入联系人号码', trigger: 'blur' }],
        administratorName: [{ required: true, message: '请输入租户姓名', trigger: 'blur' }]
      },
      formInline: {
        page: 1,
        pageSize: 10,
        tenantName: '',
        userName: '',
        searchDate: '',
        beginDate: '',
        endDate: '',
        id: ''
        // token: localStorage.getItem('logintoken')
      },
      // 删除部门
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      },
      currentSuggestion: ''
    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.search()
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    async search() {

      if (this.formInline.searchDate) {
        this.formInline.beginDate = this.formInline.searchDate[0]
        this.formInline.endDate = this.formInline.searchDate[1]
      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
      let res = await getSuggestionReq(this.formInline)
      console.log("getSuggestionReq", res)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          if (item.isEnable == 1) {
            item.isEnable = true
          } else {
            item.isEnable = false
          }
        }
      }
      // this.getdata(this.formInline)
    },    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.searchDate) {
        this.formInline.beginDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
    },
    reset() {
      this.formInline.administratorName = ''
      this.formInline.administratorPhone = ''
      this.formInline.tenantName = ''
      this.formInline.searchDate = ''
      this.formInline.endDate = ''
      this.formInline.beginDate = ''
      this.formInline.page = 1
      this.formInline.pageSize = 10
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      console.log("handleEdit", index, row)
      this.editFormVisible = true
      this.title = '反馈内容'
      this.currentSuggestion = row.content

    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        console.log("editForm **", this.editForm, valid)

        if (valid) {

          let res = await tenantSave(this.editForm)
          this.editFormVisible = false
          this.loading = false
          if (res.code === 200) {
            this.getdata(this.formInline)
            this.$message({
              type: 'success',
              message: this.editForm.id ? '更新成功' : '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '添加失败！' + res.message
            })
          }

        } else {
          return false
        }
      })
    },
    // 删除公司


    filterTag(value, row) {
      return row.isEnable === value
    },
    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
    },
    timeformat(time) {
      return time.slice(0, 10)
    },
    async handleSwitchChange(id, isEnable) {
      console.log('handleSwitchChange', id, isEnable)
      let res = await changeTenantIsEnable({ id: id, isEnable: isEnable ? 1 : 0 })
      console.log("res", res)
      if (res.code === 200) {

        this.$message({
          type: isEnable ? 'success' : 'warning',
          message: isEnable ? '启用成功！' : '禁用成功！'
        })
      } else {
        this.$message({
          type: 'error',
          message: '修改失败！' + res.message
        })
      }
      // 假设 this.tableData 是你的表格数据源  
      // this.$set(this.tableData[index], 'isEnable', value ? 1 : 0);
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.ellipsis-cell {
  display: block;
  /* 如果需要的话，确保内容作为块级元素显示 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* 可能还需要设置最大宽度，以确保内容可以溢出 */
  max-width: 100%;
  /* 或者一个具体的像素值，例如 200px */
}
</style>