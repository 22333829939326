/**
* 基础菜单 商品管理
*/
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>租户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="搜索：">
        <el-input size="small" v-model="formInline.tenantName" placeholder="输入门店名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input size="small" v-model="formInline.administratorName" placeholder="输入联系人姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input size="small" v-model="formInline.administratorPhone" placeholder="输入手机号码" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker v-model="formInline.searchDate" @change="dateChange" type="daterange" size="small"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>

      </el-form-item>
    </el-form>

    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 95%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" min-width="80">
      </el-table-column>
      <el-table-column prop="tenantName" label="门店名称" min-width="180">
      </el-table-column>
      <el-table-column prop="tenantAddress" label="门店地址" min-width="250">
      </el-table-column>
      <el-table-column prop="administratorName" label="联系人" min-width="100">
      </el-table-column>
      <el-table-column prop="administratorPhone" label="联系电话" min-width="100">
      </el-table-column>
      <el-table-column label="是否启用" align="center" :filters="enableFilter" :filter-method="filterTag"
        filter-placement="bottom-end" min-width="70">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949"
            @change="handleSwitchChange(scope.row.id, $event)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column sortable prop="createTime" label="创建时间" min-width="80">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="280">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button>
          <el-button size="mini" type="info" @click="resetUser(scope.$index, scope.row)">重置</el-button>
          <!-- <el-button size="mini" type="primary" @click="showDetail(scope.$index, scope.row)">详情</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog">
      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="门店名称" prop="tenantName">
          <el-input size="small" v-model="editForm.tenantName" auto-complete="off" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item label="门店地址" prop="tenantAddress">
          <el-input size="small" v-model="editForm.tenantAddress" auto-complete="off" placeholder="请输入门店地址"></el-input>
        </el-form-item>
        <el-form-item label="租户姓名" prop="administratorName">
          <el-input size="small" v-model="editForm.administratorName" auto-complete="off"
            placeholder="请输入租户姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="administratorPhone">
          <el-input size="small" v-model="editForm.administratorPhone" auto-complete="off"
            placeholder="请输入联系人电话"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deptList, getTenantListByConditionReq, searchTenant, resetTenant, deleteTenant, changeTenantIsEnable, tenantList, tenantSave, deptDelete } from '../../api/userMG'
import Pagination from '../../components/Pagination'
export default {
  data() {
    return {
      enableFilter: [{ text: '启用', value: true }, { text: '禁用', value: false }],
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',
      editForm: {
        tenantAddress: '',
        tenantName: '',
        administratorPhone: '',
        administratorName: '',
        token: localStorage.getItem('logintoken')
      },
      // rules表单验证
      rules: {
        tenantName: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ],
        administratorPhone: [{ required: true, message: '请输入联系人号码', trigger: 'blur' }],
        administratorName: [{ required: true, message: '请输入租户姓名', trigger: 'blur' }]
      },
      formInline: {
        page: 1,
        pageSize: 10,
        tenantName: '',
        administratorName: '',
        administratorPhone: '',
        searchDate: '',
        beginDate: '',
        endDate: '',
        date: []
        // token: localStorage.getItem('logintoken')
      },
      // 删除部门
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true

      /***
       * 调用接口，注释上面模拟数据 取消下面注释
       */
      let res = await searchTenant(this.formInline)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          if (item.isEnable == 1) {
            item.isEnable = true
          } else {
            item.isEnable = false
          }
        }

        console.log("this.listData", this.listData)

        // 分页赋值
        this.pageparm.currentPage = this.formInline.page
        this.pageparm.pageSize = this.formInline.limit
        this.pageparm.total = res.data.total
      }
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    async search() {

      if (this.formInline.searchDate) {
        this.formInline.beginDate = this.formInline.searchDate[0]
        this.formInline.endDate = this.formInline.searchDate[1]
      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
      let res = await searchTenant(this.formInline)
      console.log("search", res)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          if (item.isEnable == 1) {
            item.isEnable = true
          } else {
            item.isEnable = false
          }
        }
      }
      console.log("search", this.formInline, res)
      // this.getdata(this.formInline)
    },    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.searchDate) {
        this.formInline.beginDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
    },
    reset() {
      this.formInline.administratorName = ''
      this.formInline.administratorPhone = ''
      this.formInline.tenantName = ''
      this.formInline.searchDate = ''
      this.formInline.endDate = ''
      this.formInline.beginDate = ''
      this.formInline.page = 1
      this.formInline.pageSize = 10
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      console.log("handleEdit", index, row)
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '编辑'
        this.editForm.id = row.id
        this.editForm.tenantName = row.tenantName
        this.editForm.tenantAddress = row.tenantAddress
        this.editForm.administratorName = row.administratorName
        this.editForm.administratorPhone = row.administratorPhone
      } else {
        this.title = '添加'
        this.editForm.tenantName = ''
        this.editForm.tenantAddress = ''
        this.editForm.administratorName = ''
        this.editForm.administratorPhone = ''
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        console.log("editForm **", this.editForm, valid)

        if (valid) {

          let res = await tenantSave(this.editForm)
          this.editFormVisible = false
          this.loading = false
          if (res.code === 200) {
            this.getdata(this.formInline)
            this.$message({
              type: 'success',
              message: this.editForm.id ? '更新成功' : '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '添加失败！' + res.message
            })
          }

          // tenantSave(this.editForm)
          //   .then(res => {
          //     this.editFormVisible = false
          //     this.loading = false
          //     if (res.code === 200) {
          //       this.getdata(this.formInline)
          //       this.$message({
          //         type: 'success',
          //         message: '添加成功！'
          //       })
          //     } else {
          //       this.$message({
          //         type: 'error',
          //         message: '添加失败！' + res.message
          //       })
          //     }
          //   })
          //   .catch(err => {
          //     this.editFormVisible = false
          //     this.loading = false
          //     this.$message.error('公司保存失败，请稍后再试！')
          //   })
        } else {
          return false
        }
      })
    },
    // 删除公司
    deleteUser(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTenant({ id: row.id })
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getdata(this.formInline)
              } else {
                this.$message({
                  type: 'info',
                  message: res.message
                })
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('租户删除失败，请稍后再试！')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    resetUser(index, row) {
      this.$confirm('确定要重置租户密码吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          resetTenant({ id: row.id, password: row.administratorPhone })
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '重置密码成功!'
                })
                this.getdata(this.formInline)
              } else {
                this.$message({
                  type: 'info',
                  message: res.message
                })
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('重置密码失败，请稍后再试！')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          })
        })
    },
    filterTag(value, row) {
      return row.isEnable === value
    },
    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
    },
    timeformat(time) {
      return time.slice(0, 10)
    },
    async handleSwitchChange(id, isEnable) {
      console.log('handleSwitchChange', id, isEnable)
      let res = await changeTenantIsEnable({ id: id, isEnable: isEnable ? 1 : 0 })
      console.log("res", res)
      if (res.code === 200) {

        this.$message({
          type: isEnable ? 'success' : 'warning',
          message: isEnable ? '启用成功！' : '禁用成功！'
        })
      } else {
        this.$message({
          type: 'error',
          message: '修改失败！' + res.message
        })
      }
      // 假设 this.tableData 是你的表格数据源  
      // this.$set(this.tableData[index], 'isEnable', value ? 1 : 0);
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>